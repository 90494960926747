import fetch from "isomorphic-fetch";
import { ApolloClient, InMemoryCache } from "@apollo/client";

export const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/kdyiy300bmkw/environments/qa",
  headers: {
    Authorization: "Bearer sxGpqFRTnhO4kavzzo8vN3mH5LQMnKA57jqHpfDKjn0",
  },
  cache: new InMemoryCache(),
});
